const settings = {
  isSplash: true,
  useCustomCursor: true,
  googleTrackingID: "UA-174238252-2",
};

const greeting = {
  title: "Hello World.",
  title2: "Jaideep",
  logo_name: '"Jaideep";',
  full_name: "Jaideep Singh",
  subTitle:
    "Captivated by Flutter Development? Let Me Catapult Your Apps to New Heights• Building SuperApp @Arya.ag• Ex @Kniru🚀 • Ex @Edventure Club • Ex @UnrelAI • Ex @Rjwada • Worked with 6+ StartUps • Freelancer • Mentor • Open Source Advocate 🔥• Philonoist.",
  resumeLink:
    "https://drive.google.com/file/d/1jEIvclZLeyR4an9MR1z74le9JsgBUolt/view?usp=sharing",
  mail: "mailto:jaideep320@gmail.com",
};

const socialMediaLinks = {
  github: "https://github.com/Jaideep25-tech",
  linkedin: "https://www.linkedin.com/in/jaideep-singh-26a226208/",
  gmail: "jaideep320@gmail.com",
  instagram: "https://www.instagram.com/jaideep.singh25/",
};

const skills = {
  data: [
    {
      title: "Full Stack Development",
      fileName: "FullStackImg",
      skills: [
        "⚡Develop highly interactive Front end / User Interfaces which are capable of retaining user",
        "⚡Developing full-stack mobile applications using Flutter",
        "⚡Integration of third party services such as Firebase, Razorpay & AWS",
        "⚡Bringing Unique Ideas and Lead-by-Example Approach ",
        "⚡Expertise in a Wide Array of Technologies which helps in cooperation and team management",
        "⚡Ability to take and work on feedback.",
        "⚡Attention to detail while analyzing requirements.",
        "⚡Calling out blockers and gaps in time to the right channels.",
      ],
      softwareSkills: [
        {
          skillName: "Flutter",
          fontAwesomeClassname: "simple-icons:flutter",
          style: {
            color: "#02569B",
          },
        },
        {
          skillName: "Dart",
          fontAwesomeClassname: "simple-icons:dart",
          style: {
            color: "#29B0EE",
          },
        },
        {
          skillName: "Java",
          fontAwesomeClassname: "simple-icons:java",
          style: {
            color: "#f89820",
          },
        },
        {
          skillName: "Android",
          fontAwesomeClassname: "simple-icons:android",
          style: {
            color: "#3DDC84",
          },
        },
        {
          skillName: "Firebase",
          fontAwesomeClassname: "simple-icons:firebase",
          style: {
            color: "#ffcc30",
          },
        },
        {
          skillName: "Git",
          fontAwesomeClassname: "simple-icons:git",
          style: {
            color: "#E94E32",
          },
        },
        {
          skillName: "MySQL",
          fontAwesomeClassname: "simple-icons:mysql",
          style: {
            color: "#4479A1",
          },
        },
        {
          skillName: "JavaScript",
          fontAwesomeClassname: "simple-icons:javascript",
          style: {
            backgroundColor: "#FFFFFF",
            color: "#F7DF1E",
          },
        },
        {
          skillName: "Python",
          fontAwesomeClassname: "simple-icons:python",
          style: {
            color: "#3776AB",
          },
        },
      ],
    },
  ],
};

const degrees = {
  degrees: [
    {
      title: "Vellore Institute of Technology, Bhopal",
      subtitle: "Bachelor in Information Technology",
      logo_path: "vitb.jpg",
      alt_name: "VIT Bhopal",
      duration: "2020 - Present",
      descriptions: [
        "⚡ I'm currently pursuing my bachelors in Computer Science with specialization in CyberSecurity and Digital Forensics.",
        "⚡ I have studied core subjects like Data Structures, DBMS, Networking, Security, etc.",
      ],
      website_link: "https://vitbhopal.ac.in/",
    },
    {
      title: "The Chintels School",
      subtitle: "Intermediate",
      logo_path: "chs.png",
      alt_name: "Chintels School",
      duration: "2017-2018",
      descriptions: [
        "⚡ I have completed my 12th grade in PCM stream. ",
        "⚡ I have also went for interschool table tennis tournament and won the second prize.",
      ],
      website_link: "https://www.chintelsschool.com/",
    },
  ],
};

const certifications = {
  certifications: [
    {
      title: "Microsoft Azure Fundamentals",
      subtitle: "Microsoft",
      logo_path: "azure.png",
      certificate_link:
        "https://drive.google.com/file/d/1_QrIViphb7xGK9nKWz0BfqwVxLQ0L7j9/view",
      alt_name: "Microsoft",
      // color_code: "#2AAFED",
      color_code: "#47A048",
    },
    {
      title: "Clean Code with dart",
      subtitle: "LinkedIn Learning",
      logo_path: "linkedin learning.jpg",
      certificate_link:
        "https://drive.google.com/file/d/1xbMw8OC0dDsHgol5fPKzeKTWUXNtPj0t/view?usp=sharing",
      alt_name: "Flutter",
      color_code: "#E2405F",
    },
    {
      title: "Open source mentor for Flutter",
      subtitle: "10 days of code by IIT Madras",
      logo_path: "iit.png",
      certificate_link:
        "https://drive.google.com/file/d/1S6AEH1d8cwN98nN_7A5dqDRIiBJAulQe/view",
      alt_name: "Open source Mentor",
      // color_code: "#F6B808",
      color_code: "#47A048",
    },
    {
      title: "Open Source speaker's certificate",
      subtitle: "Workshop at GirlScript Chapter Jaipur",
      logo_path: "gsj.jpg",
      certificate_link:
        "https://drive.google.com/file/d/137zTi4nhkN2pZd0nGm69nK9xC7hdLWKU/view",
      alt_name: "Workshop",
      color_code: "#2AAFED",
    },
    {
      title: "Native Android Development Internship",
      subtitle: "Sparks Foundation",
      logo_path: "sparks.png",
      alt_name: "Android Development",
      color_code: "#fe0037",
    },
    {
      title: "Java Level 2",
      subtitle: "Cambridge Certification Authority",
      logo_path: "cca.jpg",
      certificate_link:
        "https://www.the-cca.org/?watupro_view_certificate=1&taking_id=116863&id=1",
      alt_name: "Developemnt with Java",
      color_code: "#2AAFED",
    },
    {
      title: "Java Level 1",
      subtitle: "Cambridge Certification Authority",
      logo_path: "cca.jpg",
      certificate_link:
        "https://www.the-cca.org/?watupro_view_certificate=1&taking_id=115913&id=1",
      alt_name: "Introduction to Developemnt with Java",
      color_code: "#fffbf3",
    },
    {
      title: "Professional Certificate in Java Programming",
      subtitle: "Udemy",
      logo_path: "udemy.png",
      certificate_link:
        "https://www.udemy.com/certificate/UC-6e7cbc95-1979-484b-8c22-503f67359f6e/",
      alt_name: "Professional Developemnt with Java",
      color_code: "#fffbf3",
    },
  ],
};

const experience = {
  title: "Experience",
  subtitle: "Work, Internship and Volunteership",
  description:
    "Captivated by Flutter Development? Let Me Catapult Your Apps to New Heights• Building SuperApp @Arya.ag• Ex @Kniru🚀 • Ex @UnrelAI • Ex @Rjwada • Worked with 6+ StartUps • With almost 3 years of profession experience in the field of SDE precisely Mobile application Development• Freelancer • Flutter • Firebase • Mentor. Looking for better oppurtunities in SDE role. I love organizing workshops to share my knowledge with others.",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Work Experience",
      experiences: [
        {
          title: "ASDE",
          company: "Arya.ag",
          company_url: "https://www.arya.ag/",
          logo_path: "arya_ag.jpeg",
          duration: "Jan 2023 - Present ",
          location: "Noida, Uttar Pradesh",
          description: `• Responsible for converting native android app into Flutter cross platform applicartion. \n
          • Responsible for developing some urgent requirements.in native application built in Kotlin\n
          • Responisble for releasing application on respective platform.\n
          • Add new features and look after previously added features in the main Arya.ag flutter application
          `,
          color: "#0071C5",
        },
        {
          title: "ASDE Intern",
          company: "Arya.ag",
          company_url: "https://www.arya.ag/",
          logo_path: "arya_ag.jpeg",
          duration: "Oct 2023- Jan 2023",
          location: "Noida, Uttar Pradesh",
          description: `• Developing Front-End to introduce dynamic functionality, Developed Complex Front-End Components which
          are going to accommodate thousands of users. \n
          • Integrated Biometrics use and working with GetX state-management while maintaining MVVM architecture\n
          • Worked with a team maintaining healthy friendly environment.
          `,
          color: "#0071C5",
        },
        {
          title: "Flutter Developer Intern",
          company: "Kniru",
          company_url: "https://www.kniru.com/",
          logo_path: "kniru.jpeg",
          duration: "Jun 2023- Aug 2023",
          location: "Indore, Madhya Pradesh",
          description: `• Developing Front-End to introduce dynamic functionality, Developed Complex Front-End Components which
          are going to accommodate thousands of users. \n
          • Integrating BLoC state management with 17+ states.\n
          • Worked with a team of 6 people maintaining healthy friendly environment.
          `,
          color: "#0071C5",
        },
        {
          title: "SDE Intern",
          company: "Edventure Club",
          company_url: "http://sos42.club",
          logo_path: "sos42.jpeg",
          duration: "May 2022 - Jun 2023",
          location: "Banglore, Karnataka",
          description: `• Built the product from scratch and released it on App and Play Store. \n
          • Developed pixel-perfect UI that served as the major medium of user retention. \n
          • Integrated different Services and Firestore database of Firebase in the backend.
          `,
          color: "#0071C5",
        },
        {
          title: "Senior Mobile Application Developer",
          company: "Rjwada, A Startup of IIT Delhi",
          company_url: "https://rjwada.com/",
          logo_path: "rjwada.jpg",
          duration: "Aug 2022 - Oct 2022",
          location: "Delhi",
          description: `• Creating cross-platform E-COmmerce mobile application from Scratch in a flutter.\n
          • Integrated AR/vR model built in unity in flutter application to virtually try real time view of garment on oneself.\n
          • Integrating Firebase for authentication and firebase storage.\n
          • Working with API and performing CRUD operations for designing different functionalities of application.
          `,
          color: "#0071C5",
        },
        {
          title: "Flutter Developer Intern",
          company: "UnrealAI",
          company_url: "https://unrealai.xyz/",
          logo_path: "unreal.png",
          duration: "Jun 2022 - Aug 2022",
          location: "Work From Home",
          description: `• Working on a flutter mobile application. \n
          • Integrating different services of AWS like S3, EC2, Lambda, etc. \n
          • Working with firebase in backend.

          `,
          color: "#ee3c26",
        },
      ],
    },
    {
      title: "Volunteerships",
      experiences: [
        {
          title: "Flutter Mentor",
          company: "CodeIn Community",
          company_url: "https://codeincommunity.tech/",
          logo_path: "codein.jpg",
          duration: "Jun 2022 - Dec 2022",
          location: "Work From Home",
          description:
            "Help Tech-Geeks to learn, improve themselves at flutter and solve their doubts. Helping them to build their projects.",
          color: "#FBBD18",
        },
        {
          title: "Android Dvelopment Mentor",
          company: "Codecademy Central India Chapter",
          company_url:
            "https://www.linkedin.com/company/codecademy-central-india-chapter/?originalSubdomain=in",
          logo_path: "code.svg",
          duration: "Jan 2022 - Jul 2022",
          location: "Work From Home",
          description:
            "Help Tech-Geeks to learn, improve themselves at Android Development and solve their doubts. Organize sessions on different topics of android development. Helping them to build their projects",
          color: "#4285F4",
        },
        {
          title: "Technical Team Executive Member",
          company: "Codechef VITB Chapter",
          company_url: "https://codechefvitb.tech/",
          logo_path: "codec.jpg",
          duration: "Nov 2021 - Nov 2022",
          location: "Work From Home",
          description:
            "Organise tech workshops and help the members of the community with their doubts and issues.",
          color: "#D83B01",
        },
        {
          title: "Project Admin",
          company: "GirlScript Summer of Code'2022",
          company_url: "https://gssoc.girlscript.tech/",
          logo_path: "gswoc.png",
          duration: "Mar 2022 - May 2022",
          location: "Work From Home",
          description:
            "Helping the students to learn and contribute to open source projects. Assisting them with commands and use of Git/GitHub.",
          color: "#D83B01",
        },
        {
          title: "Open Source Mentor",
          company: "WOC 2.0 By IIITK",
          company_url: "https://gdsc-woc.devfolio.co/",
          logo_path: "woc.jpg",
          duration: "Nov 2021 - Feb 2022",
          location: "Work from Home",
          description:
            "Mentorship responsibilities were to help students plan the project, review issues and pull requests, ensure smooth progress and help them out when they are stuck.",
          color: "#040f26",
        },
        {
          title: "Program Manager",
          company: "Scrip Winter of Code'21",
          company_url: "https://swoc.getsocialnow.co/",
          logo_path: "swoc.jpg",
          duration: "Nov 2021 - Jan 2022",
          location: "Work From Home",
          description:
            "Manage the whole program and make the function smooth. Make sure the contributions happens ina right way and they get support from mentors and project admins",
          color: "#D83B01",
        },
        {
          title: "Open Source Contributor",
          company: "GirlScript Winter of Code'2021",
          company_url: "https://gwoc.girlscript.tech/",
          logo_path: "gswoc'21.png",
          duration: "Aug 2021 - Nov 2021",
          location: "Work From Home",
          description:
            "Contributing to open source projects in field of Java, and was one of the top 10 contributors",
          color: "#5a900f",
        },
      ],
    },
  ],
};

const projectsHeader = {
  title: "Projects",
  description:
    "My projects make use of a vast variety of latest technology tools. My best experience is to Create cross platfrom mobile application with help of flutter and support its backend with help of Firebase. Currently I am learning to build unique animated applications in flutter being inspired from dribble. Below are some of my projects. Note that not all of the mentioned projects are on GitHub yet.",
  avatar_image_path: "projects_image.svg",
};

const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: "jds.jpg",
    description:
      "You can contact me at the places mentioned below. I will try to get back to you as fast as I can. ",
  },
};

const projects = {
  data: [
    {
      id: "0",
      name: "Medie Buddy",
      url: "https://github.com/Jaideep25-tech/medical_flutter_ui",
      description:
        "Built a cross-platform mobile application using Flutter which helps users to Book appointments with the doctor and much more. In short a medical assistant to look after each and every need of a patient ",
      languages: [
        {
          name: "Flutter",
          iconifyClass: "logos-flutter",
        },
      ],
    },
    {
      id: "1",
      name: "Rubik's Cube Solver",
      url: "https://github.com/Jaideep25-tech/Rubiks_cube_solver",
      description:
        "Build a mobile application using Flutter that provides a solution for 3*3 Rubik's Cube using Python's AI libraries like Rubik Solver, Rubiks Cube Tracker, OpenCV",
      languages: [
        {
          name: "Flutter",
          iconifyClass: "logos-flutter",
        },
        {
          name: "Python",
          iconifyClass: "logos-python",
        },
      ],
    },
    {
      id: "2",
      name: "Tampa",
      url: "",
      description:
        "Build while interning at Rjwada. Ecommerce app integrated with AR/VR model which can be used to see the realtime look of outfit selected on ourself.",
      languages: [
        {
          name: "Flutter",
          iconifyClass: "logos-flutter",
        },
        {
          name: "Unity",
          iconifyClass: "logos-unity",
        },
      ],
    },
    {
      id: "3",
      name: "Kryptos3dit",
      url: "https://github.com/Jaideep25-tech/kryptos3dit",
      description:
        "Desktop application that can encrypt/decrypt upto 100 mb videos within 20 seconds. We can also apply filters to images and embed text in that moreover we also get google embeded to sirf images.",
      languages: [
        {
          name: "Java",
          iconifyClass: "logos-java",
        },
        {
          name: "Python",
          iconifyClass: "logos-python",
        },
      ],
    },
    {
      id: "4",
      name: "Quiz app",
      url: "https://github.com/Jaideep25-tech/quiz_app",
      description:
        "Build a Simple Quiz application in Flutter that uses BLoC state Management and fetches data of the quiz from the API and displays the result at end",
      languages: [
        {
          name: "Flutter",
          iconifyClass: "logos-flutter",
        },
      ],
    },
    {
      id: "5",
      name: "Bubble Trouble game",
      url: "https://github.com/Jaideep25-tech/bubbleTrouble",
      description:
        "Bubble trouble game build in Flutter while learning it. Made responsive so that we can play on mobile as well as web and we can also make use of keyboard to control the game",
      languages: [
        {
          name: "Flutter",
          iconifyClass: "logos-flutter",
        },
      ],
    },
    {
      id: "6",
      name: "Animated Movie Show Booking app",
      url: "https://github.com/Jaideep25-tech/flutter_movies",
      description:
        "A Animated Movie show booking app, Trying to renovate User Experience with help of vast feasability of animations provided by flutter. Built on being inspired from Dribble Design ",
      languages: [
        {
          name: "Flutter",
          iconifyClass: "logos-flutter",
        },
      ],
    },
    {
      id: "7",
      name: "Netflix Clone",
      url: "",
      description:
        "Built while learning firebase and its integration in flutter. Frontend built using Flutter and authentication using firebase. Made use of Firestore to store data and firebase storage to store images.",
      languages: [
        {
          name: "Flutter",
          iconifyClass: "logos-flutter",
        },
        {
          name: "Firebase",
          iconifyClass: "logos-firebase",
        },
      ],
    },
    {
      id: "8",
      name: "Basic Banking Application",
      url: "https://github.com/Jaideep25-tech/bankingApplication",
      description:
        "A simple native Android banking application built that uses sql database built in java that allows user to make transactions from one account to other like a real time bank transfer and maintains record of transactions and balance as well.",
      languages: [
        {
          name: "Java",
          iconifyClass: "logos-java",
        },
        {
          name: "SQL",
          iconifyClass: "logos-sql",
        },
      ],
    },
  ],
};

export {
  settings,
  greeting,
  socialMediaLinks,
  skills,
  degrees,
  certifications,
  experience,
  projectsHeader,
  contactPageData,
  projects,
};
